import firebase from 'firebase/app'
import store from '@/store'

export default async function setUserInfo(payload) {
  try {
    store.dispatch('userBasic/updateCoin', payload.coin)
    store.dispatch('userBasic/updateUid', payload.uid)
    store.dispatch('userBasic/updateMail', payload.mail)
    store.dispatch('userBasic/updateUserType', payload.userType)

    let target = ''
    let userID = ''
    if (payload.userType === 'student') target = 'studentID'; userID = payload.studentID
    if (payload.userType === 'teacher') target = 'teacherID'; userID = payload.teacherID
    if (payload.userType === 'company') target = 'companyID'; userID = payload.companyID

    let dataDic = {}
    await firebase.firestore().collection('profile')
      .where(target, '==', userID)
      .get()
      .then(sn => {
        if (!sn.empty) {
          sn.forEach(doc => {
            dataDic = doc.data()
          })
        }
      })

    store.dispatch('userBasic/updateUserNickName', dataDic.nickname)

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error.msg,
    }
    return response
  }
}
