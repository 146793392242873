<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            タッチファミリーケア
          </h2>
        </b-link>

        <div class="mb-1 text-center text-sub">
          ようこそ、タッチファミリーケアへ！
        </div>
        <b-card-text class="mb-2">
          <b-alert
            variant="primary"
            show
            class=""
          >
            <div class="alert-body mt-1 mb-2">
              <div class="d-flex">
                <div class="">
                  <b-avatar
                    size="40"
                    src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                    variant="light-primary"
                  />
                </div>
                <div class="ml-1">
                  メールアドレスとパスワードを入力して下さい。
                </div>
              </div>
            </div>
          </b-alert>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="mail"
              label="Mail"
            >
              <validation-provider
                name="Mail"
              >
                <b-form-input
                  id="mail"
                  v-model="mailaddress"
                  name="login-email"
                  placeholder="1234"
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge input_"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <div class="">
              <b-button
                variant="flat-primary"
                class="btn-wishlist"
                block
                @click="forget"
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  class="mr-50"
                />
                <small>パスワードをお忘れの場合はこちらから</small>
              </b-button>
            </div> -->
            <div
              v-if="showPasswdError"
              class="text-danger text-center"
            >
              パスワードが正しくありません。
            </div>

            <!-- submit button -->
            <b-button
              v-if="status == 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0 0 0;"
              @click="login"
            >
              ログイン
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
            <div class="mt-2">
              <b-button
                variant="outline-danger"
                block
                @click="register"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  class="mr-50"
                />
                <small>アカウントをお持ちでない方はこちらから</small>
              </b-button>
            </div>
            <div class="">
              <b-button
                variant="flat-primary"
                class="btn-wishlist"
                block
                @click="forget"
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  class="mr-50"
                />
                <small>パスワードをお忘れの場合はこちらから</small>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink,
  BCardText, BInputGroup, BInputGroupAppend, BSpinner,
  BAlert, BAvatar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import firebase from 'firebase/app'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import setUserInfo from '@/firestore/authentication/updateStore'
import store from '@/store'
import LoginHistory from '@/firestore/authentication/LoginHistory'
import StoreUserInfo from '@/firestore/data/StoreUserInfo'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorMSG: '',
      required,
      email,
      status: 1,
      mailaddress: '',
      password: '',
      showPasswdError: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    async login() {
      this.status = 2
      this.showPasswdError = false
      const isLogined = await firebase.auth().signInWithEmailAndPassword(this.mailaddress, this.password)
        .then(
          res => {
            store.dispatch('userBasic/updateUid', res.user.uid)
            store.dispatch('userBasic/updateMail', res.user.email)
            store.dispatch('userBasic/updateIsLogin', true)
            return true
          },
        )
        .catch(error => {
          window.console.log('memo', error.message)
          if (error.message === 'The password is invalid or the user does not have a password.') this.showPasswdError = true
        })
      if (isLogined) {
        const history = new LoginHistory()
        await history.update()

        const storeUserInfo = new StoreUserInfo()
        await storeUserInfo.update()

        await firebase.firestore().collection('user')
          .where('mail', '==', this.mailaddress)
          .get()
          .then(sn => {
            if (!sn.empty) {
              sn.forEach(doc => {
                const data = doc.data()
                /* eslint-disable */
                setUserInfo(data)
                store.dispatch('userBasic/updateUserType', data.userType)
                this.$router.replace(getHomeRouteForLoggedInUser(data.userType))
                this.status = 1
                /* eslint-enable */
              })
            }
          })
        return
      }
      this.status = 1
    },
    /**
     * クエリを持っている場合はそのまま反映。
     * 持っていない場合はstudentだけを指定する：それ以外のユーザタイプでは登録させない
     */
    register() {
      if (this.$route.query.type) this.$router.push({ path: '/sendmail', query: { type: this.$route.query.type } })
      else this.$router.push({ path: '/sendmail', query: { type: 'student' } })
    },
    forget() {
      this.$router.push({ name: 'forget' })
    },
    setInitialData() {
      this.$store.dispatch('userBasic/updateUserDic', {})
      const d = {
        patientID: this.$route.query.patientID || '',
        type: this.$route.query.type || 'student',
        applyID: this.$route.query.applyID || null,
      }
      this.$store.dispatch('userBasic/updateUserDic', JSON.parse(JSON.stringify(d)))
      // window.console.log('memo', d, store.state.userBasic.userDic.patientID)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.spinner_ {
  margin: 3% auto;
}
.input_ {
  font-size: 1.4rem;
  letter-spacing: 3px;
}
</style>
