import store from '@/store'
import GetUserCollection from './GetUserCollection'

export default class StoreUserInfo {
  constructor() {
    this.label = ''
  }

  async update() {
    try {
      const userCollection = new GetUserCollection()
      const res = await userCollection.fetch()
      if (res.status === 'error') return { status: 'error', msg: res.msg }
      if (res.data.adminID) store.dispatch('userBasic/updateAdminID', res.data.adminID)
      if (res.data.studentID) store.dispatch('userBasic/updateStudentID', res.data.studentID)
      if (res.data.jobSeekerID) store.dispatch('userBasic/updateJobSeekerID', res.data.jobSeekerID)
      if (res.data.teacherID) store.dispatch('userBasic/updateTeacherID', res.data.teacherID)
      if (res.data.companyID) store.dispatch('userBasic/updateCompanyID', res.data.companyID)
      if (res.data.groupID) store.dispatch('userBasic/updateGroupID', res.data.groupID)
      if (res.data.userTypeList) store.dispatch('userBasic/updateUserTypeList', res.data.userTypeList)
      return { status: 'success' }
    } catch (error) {
      return { status: 'error', msg: error }
    }
  }
}
