<template>
  <div>
    <sign-up />
  </div>
</template>

<script>
import SignUp from '@/components/authentication/SignUp.vue'

export default {
  components: {
    SignUp,
  },
}
</script>
